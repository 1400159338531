@font-face {
    font-family: 'gothamregular';
    src: url('../fonts/gotham_regular-webfont.woff2') format('woff2'),
         url('../fonts/gotham_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamblack';
    src: url('../fonts/gotham-black-webfont.woff2') format('woff2'),
         url('../fonts/gotham-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothambold';
    src: url('../fonts/gotham-bold-webfont.woff2') format('woff2'),
         url('../fonts/gotham-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothambold';
    src: url('../fonts/gothambold-webfont.woff2') format('woff2'),
         url('../fonts/gothambold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothambold_italic';
    src: url('../fonts/gothambolditalic-webfont.woff2') format('woff2'),
         url('../fonts/gothambolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothambook';
    src: url('../fonts/gothambook-webfont.woff2') format('woff2'),
         url('../fonts/gothambook-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothambookitalic';
    src: url('../fonts/gotham-bookitalic-webfont.woff2') format('woff2'),
         url('../fonts/gotham-bookitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothambook_italic';
    src: url('../fonts/gothambookitalic-webfont.woff2') format('woff2'),
         url('../fonts/gothambookitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamlight';
    src: url('../fonts/gotham-light-webfont.woff2') format('woff2'),
         url('../fonts/gotham-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamlight';
    src: url('../fonts/gothamlight-webfont.woff2') format('woff2'),
         url('../fonts/gothamlight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamlight_italic';
    src: url('../fonts/gothamlightitalic-webfont.woff2') format('woff2'),
         url('../fonts/gothamlightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothammedium';
    src: url('../fonts/gothammedium_1-webfont.woff2') format('woff2'),
         url('../fonts/gothammedium_1-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothammedium';
    src: url('../fonts/gothammediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/gothammediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamthin';
    src: url('../fonts/gotham-thin-webfont.woff2') format('woff2'),
         url('../fonts/gotham-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamthinitalic';
    src: url('../fonts/gotham-thinitalic-webfont.woff2') format('woff2'),
         url('../fonts/gotham-thinitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamultraitalic';
    src: url('../fonts/gotham-ultraitalic-webfont.woff2') format('woff2'),
         url('../fonts/gotham-ultraitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamxlight';
    src: url('../fonts/gotham-xlight-webfont.woff2') format('woff2'),
         url('../fonts/gotham-xlight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothamxlightitalic';
    src: url('../fonts/gotham-xlightitalic-webfont.woff2') format('woff2'),
         url('../fonts/gotham-xlightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gothammedium';
    src: url('../fonts/gothammedium-webfont.woff2') format('woff2'),
         url('../fonts/gothammedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


html,
body {
  margin: 0%;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-weight: 400;
  font-family: 'gothambook';
  background-color: #f3f8f6;
}


.navbar {
	background-color: #385b4f;
}
.navbar .navbar-brand {
	padding: 0px;
	margin: 0 auto;
}
.quiz-box .quiz-chk-box {
	width: 50%;
	margin: 0 auto;
}
.quiz-box .quiz-name {
	color: #385B4F;
	font-family: 'gothammedium';
}
.quiz-box .quiz-chk-box .form-check {
	color: #578676;
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	margin-bottom: 0.6rem;
	font-family: 'gothammedium';
    border-radius: 2px;
    border: 4px solid #FFF;
	background-color: #FFF;
	box-shadow: 0 2px 4px rgba(11,59,60,.05);
}
.quiz-box .form-check .form-check-input {
	margin-left: 0px;
	margin-right: 6px;
}
.quiz-box .form-check-input:checked {
	background-color: #d4b433;
    border-color: #d4b433;
}
.quiz-box .form-check-input:focus {
	box-shadow: none;
}
.quiz-box .nxt-btn .btn {
	padding: 1em 1.8em;
	border-radius: 50px;
	border-color: #578676;
	background-color: #70A895;
}
.quiz-box .nxt-btn .btn:hover {
	background-color: #385b4f;
}

.assessment-box .card-title {
	color: #578676;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-align: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.175);
	font-family: 'gothammedium';
}
.assessment-box .card-title span {
	color: #FFF;
    padding: 5px 8px;
    border-radius: 3px;
	display: inline-block;
	background-color: #385B4F;
}
.assessment-box .card-body p {
	color: #578676;
}