@media screen and (min-width: 1600px) {}

@media screen and (min-width: 1440px) {}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
    
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
    
}

@media screen and (max-width: 991px) {
    
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    
}

@media screen and (max-width: 767px) {
    .quiz-box .quiz-chk-box {
        width: 80%;
    }
}

@media screen and (max-width: 480px) {}

@media screen and (max-width: 320px) {
    
}